<template>
    <base-table models-name="workspace"
                model-ref="il workspace"
                new-msg="un nuovo workspace"
                :is-loading="isLoading"
                :headers="headers"
                :can-create="$store.getters.isSystem"
                @changeOptions="onChangeOptions($event)"
    >
        <template #header="{ onChangeFilter }">
            <workspace-table-header @changeOptions="onChangeOptions($event)"
                                    @changeFilter="onChangeFilter"
            ></workspace-table-header>
        </template>

        <template #UT_COUNT="{ item }">
            <button-tooltip x-small
                            fab
                            :text="''+item.UT_COUNT"
                            color="primary"
                            :to="'/secure/utenti?wrk=' + item.ID_REC"
                            exact
                            :tooltip="item.NOME + ' possiede ' + item.UT_COUNT + ' utenze associate'"
            ></button-tooltip>
        </template>

        <template #CL_COUNT="{ item }">
            <button-tooltip x-small
                            fab
                            :text="''+item.CL_COUNT"
                            color="primary"
                            :to="'/secure/clienti?wrk=' + item.ID_REC"
                            exact
                            :tooltip="item.NOME + ' possiede ' + item.CL_COUNT + ' clienti associati'"
            ></button-tooltip>
        </template>

        <template #EV_COUNT="{ item }">
            <button-tooltip x-small
                            fab
                            :text="''+item.EV_COUNT"
                            color="primary"
                            :to="'/secure/eventi?wrk=' + item.ID_REC"
                            exact
                            :tooltip="item.NOME + ' possiede ' + item.EV_COUNT + ' eventi associati'"
            ></button-tooltip>
        </template>

    </base-table>
</template>

<script>
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import WorkspaceTableHeader from '@/components/secure/workspace/components/WorkspaceTableHeader';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'WorkspaceTable',
        components: {ButtonTooltip, WorkspaceTableHeader, BaseTable},
        props: {isLoading: Boolean},
        data: () => ({
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: 'Stato ',
                    value: 'ERRORE',
                    align: 'center'
                },
                {
                    text: 'Nome ',
                    value: 'NOME',
                    align: 'center'
                },
                {
                    text: 'Utenti ',
                    value: 'UT_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Clienti ',
                    value: 'CL_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Eventi ',
                    value: 'EV_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Visualizzazioni ',
                    value: 'VISUALIZZAZIONI',
                    align: 'center'
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            }
        }
    }
</script>

<style scoped>

</style>
