<template>
    <base-sezione models-name="workspace">

        <template #table="{ isLoading, onChangeOptions }">
            <workspace-table :is-loading="isLoading" @changeOptions="onChangeOptions"></workspace-table>
        </template>

    </base-sezione>
</template>

<script>
    import BaseSezione from '@/components/aaaProject/commons/BaseSezione';
    import WorkspaceTable from '@/components/secure/workspace/components/WorkspaceTable';

    export default {
        name: 'Workspace',
        components: {WorkspaceTable, BaseSezione}
    }
</script>

<style scoped>

</style>
