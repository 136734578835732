<template>
    <base-select-field :label="label"
                       v-model="value"
                       :items="items"
                       item-text="label"
                       item-value="value"
                       :disabled="!!isGlobalLoading"
                       :clearable="value !== null"
                       @input="$emit('changeOptions', {[keyOptions]: value})"
    ></base-select-field>
</template>

<script>
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';

    export default {
        name: 'BaseTableHeaderSelect',
        components: {BaseSelectField},
        props: {
            label: String,
            items: Array,
            itemText: {type: [String, Array, Function], default: 'label'},
            itemValue: {type: [String, Array, Function], default: 'value'},
            keyOptions: String
        },
        data: () => ({
            value: null
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        }
    }
</script>

<style scoped>

</style>
