var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"workspace","model-ref":"il workspace","new-msg":"un nuovo workspace","is-loading":_vm.isLoading,"headers":_vm.headers,"can-create":_vm.$store.getters.isSystem},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('workspace-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"UT_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"x-small":"","fab":"","text":''+item.UT_COUNT,"color":"primary","to":'/secure/utenti?wrk=' + item.ID_REC,"exact":"","tooltip":item.NOME + ' possiede ' + item.UT_COUNT + ' utenze associate'}})]}},{key:"CL_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"x-small":"","fab":"","text":''+item.CL_COUNT,"color":"primary","to":'/secure/clienti?wrk=' + item.ID_REC,"exact":"","tooltip":item.NOME + ' possiede ' + item.CL_COUNT + ' clienti associati'}})]}},{key:"EV_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"x-small":"","fab":"","text":''+item.EV_COUNT,"color":"primary","to":'/secure/eventi?wrk=' + item.ID_REC,"exact":"","tooltip":item.NOME + ' possiede ' + item.EV_COUNT + ' eventi associati'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }